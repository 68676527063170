import React from 'react'
import PropTypes from 'prop-types'

const Loading = (props) => {
  const sizeInPixels = `${props.size}px`
  const viewBoxDimension = props.size / 2
  return (
    <svg
      width={sizeInPixels}
      height={sizeInPixels}
      xmlns='http://www.w3.org/2000/svg'
      viewBox={`0 0 ${viewBoxDimension} ${viewBoxDimension}`}
      preserveAspectRatio='xMidYMid'
      className='lds-dual-ring'
    >
      <circle
        cx='50'
        cy='50'
        fill='none'
        strokeLinecap='round'
        r='20'
        strokeWidth='2'
        stroke='#283891'
        strokeDasharray='31.41592653589793 31.41592653589793'
        transform='rotate(150 50 50)'
      >
        <animateTransform
          attributeName='transform'
          type='rotate'
          calcMode='linear'
          values='0 50 50;360 50 50'
          keyTimes='0;1'
          dur='3s'
          begin='0s'
          repeatCount='indefinite'
        />
      </circle>
      <circle
        cx='50'
        cy='50'
        fill='none'
        strokeLinecap='round'
        r='15'
        strokeWidth='2'
        stroke='#0FB0E2'
        strokeDasharray='23.561944901923447 23.561944901923447'
        strokeDashoffset='23.561944901923447'
        transform='rotate(-150 50 50)'
      >
        <animateTransform
          attributeName='transform'
          type='rotate'
          calcMode='linear'
          values='0 50 50;-360 50 50'
          keyTimes='0;1'
          dur='3s'
          begin='0s'
          repeatCount='indefinite'
        />
      </circle>
    </svg>
  )
}

Loading.propTypes = {
  size: PropTypes.number.isRequired
}

Loading.defaultProps = {
  size: 200
}

export default Loading
