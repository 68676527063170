import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'react-app-polyfill/ie11'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/browser'

// Sentry error reporting
const sentryDSN = process.env.REACT_APP_SENTRY_DSN

if (sentryDSN) {
  Sentry.init({
    dsn: sentryDSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    integrations: [
      new Sentry.Integrations.GlobalHandlers({
        onunhandledrejection: false,
        onerror: false
      })
    ],
    release: process.env.REACT_APP_PACKAGE_VERSION
  })
}

ReactDOM.render(<App />, document.getElementById('root'))

serviceWorker.register()
