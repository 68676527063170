import React, { Component, Suspense } from 'react'
// import PropTypes from 'prop-types'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { ToastContainer } from 'react-toastify'

import reducers from './reducers'
import Routes from './routes'
import Loading from './components/loading'
import ErrorBoundary from './components/errorBoundary'

import './styles/app.scss'

const initialState = {}
const enhancers = []
const middleware = [thunk]

const composedEnhancers = composeWithDevTools(
  applyMiddleware(...middleware),
  ...enhancers
)

const store = createStore(reducers, initialState, composedEnhancers)

class App extends Component {
  render () {
    return (
      <Suspense fallback={<div className='text-center'><Loading /></div>}>
        <Provider store={store}>
          <ToastContainer />
          <ErrorBoundary>
            <Routes />
          </ErrorBoundary>
        </Provider>
      </Suspense>
    )
  }
}

App.propTypes = {}

export default App
