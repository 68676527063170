const initialState = {
  user: {},
}

export default function users (state, action) {
  if (typeof state === 'undefined') {
    return initialState
  }

  switch (action.type) {
    default:
      return state
  }
}
