import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/browser'
import { Alert, Button } from 'reactstrap'

export class ErrorBoundary extends Component {
  constructor (props) {
    super(props)
    this.state = { eventId: null }
  }

  static getDerivedStateFromError () {
    return { hasError: true }
  }

  componentDidCatch (error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo)
      const eventId = Sentry.captureException(error)
      this.setState({ eventId })
    })
  }

  render () {
    if (this.state.hasError) {
      // render fallback UI
      return (
        <div>
          <Alert color='danger'>There has been a problem</Alert>
          <Button
            color='info'
            onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}
          >
            Report feedback
          </Button>
        </div>
      )
    }

    // when there's not an error, render children untouched
    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired
}

export default ErrorBoundary
