const initialState = {
  options: {}
}

export default function config (state, action) {
  if (typeof state === 'undefined') {
    return initialState
  }

  switch (action.type) {
    default:
      return state
  }
}
