import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

// import ProtectedRoute from './components/protectedRoute'
import Modules from './modules'

export const Routes = (props) => {
  return (
    <Router>
      <Switch>
        {(props.user.id && props.user.statusId === 7) ? (<Route path='*' component={Modules.AccountDetails} />) : null}
        <Route path='/' component={Modules.Home} />
        <Route path='/404' component={Modules.ErrorNotFound} />
        {/* <ProtectedRoute
          path='/login'
          hasAccess={!props.user.id}
          component={Modules.Login}
          redirectTo={props.loginRedirectUrl}
        />
        <ProtectedRoute
          path='/logout'
          hasAccess={props.user.id}
          component={Modules.Logout}
          redirectTo='/'
        /> */}
        <Route component={Modules.ErrorNotFound} />
      </Switch>
    </Router>
  )
}

Routes.propTypes = {
  history: PropTypes.any.isRequired
}

Routes.defaultProps = {
}

export function mapStateToProps (state) {
  return {
    user: state.users.user
  }
}

export default connect(mapStateToProps, {})(Routes)
